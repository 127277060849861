import React, { useState } from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import image from "../../assets/images/courses/precshool-curr.jpg";
import { Modal, Button } from "react-bootstrap";
import HubspotContactForm from "./hubspot/HubspotContactForm";

const CoursesPage = () => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Layout pageTitle="KWEC | Course Details">
      <Navbar />
      <PageHeader title="Course Details" />
      <section className="course-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="course-details__content">
                <div className="course-details__top">
                  <div className="course-details__top-left">
                    <h2 className="course-details__title">
                      Preschool Curriculum
                    </h2>
                  </div>
                  <div className="course-details__top-right">
                    <a className="course-one__category">For schools</a>
                  </div>
                </div>
                <div className="course-one__image">
                  <img src={image} alt="" />
                </div>
                <div className="tab-content course-details__tab-content ">
                  <div
                    className="tab-pane show active  animated fadeInUp"
                    role="tabpanel"
                    id="overview"
                  >
                    <p className="course-details__tab-text">
                      {" "}
                      We really can’t be doing what we do so well unless we can
                      describe what we do, as a process. In the world of early
                      childhood education, the curriculum is the process!
                    </p>
                    <br />
                    An amalgamation of the essential subjects critical to the
                    development of young children with innovative teaching
                    methods followed worldwide, is what outlines our curriculum.
                    Through our world-class curriculum, we facilitate in shaping
                    your child’s growth story during their momentous formative
                    years.
                    <h3>
                      <br />
                      Salient Features of the Service
                    </h3>
                    <ul>
                      <li>
                        Holistic curriculum based on National Council of
                        Education Research and Training (NCERT) guidelines.
                      </li>
                      <li>
                        Age and developmentally appropriate curriculum design
                        focussing on the following competencies.
                        <ul>
                        <li>Sensory and perceptual development</li>
                        <li>Physical health and motor development</li>
                        <li>Linguistic skills development</li>
                        <li>Cognitive development</li>
                        <li>
                          Development of creative and aesthetic appreciation
                        </li>
                        <li>Personal, social and emotional development</li>
                        </ul>
                      </li>
                      <li>
                      The curriculum covers the fundamental aspects of following subjects. 
                      <ul>

                                            <li>Languages</li>
                                            <li>Mathematics</li>
                                            <li>General knowledge</li>
                                            <li>Art and craft</li>
                                            <li>Technology</li>
                                            <li>Games and activities</li>
                                        </ul>
                      </li>
                    </ul>
                    We help to customize and implement our curriculum design in existing early childhood educational institutions, as well.
                    <br />
                    Connect with us to know more! 
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="course-details__price">
                <p className="course-details__price-text">
                  Talk to our executive and enroll now!{" "}
                </p>
                <Button
                  onClick={handleShow}
                  className="thm-btn course-details__price-btn"
                  variant="default"
                >
                  Enquire Now
                </Button>
              </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HubspotContactForm />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
